import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  Modal,
  message,
  TablePaginationConfig,
  Divider,
} from "antd";
import { Layout } from "layout";
import {
  QuestionChoiceDto,
  QuestionChoicesClient,
  QuestionChoiceListsClient,
} from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import QuestionMenu from "@components/Admin/Question/QuestionMenu";
import ContentTable from "@components/Admin/ContentTable";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import AsyncSelect from "lib/AsyncSelect";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Questions", "QuestionChoices");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };
  const choiceClient = new QuestionChoicesClient(undefined, allLanguageFetch);
  const choiceListClient = new QuestionChoiceListsClient();

    
  const { formatMessage } = useIntl();
  const StaticItemPerPage = formatMessage({ id: "StaticItemPerPage" });
  const defaultPagination: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ["topRight", "bottomRight"],
    locale: { items_per_page: " / "+ StaticItemPerPage }
  };
  const [state, setState] = useState({
    items: [] as QuestionChoiceDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const [filterChoiceListId, setFilterChoiceListId] = useState(
    undefined as number | undefined | null
  );
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = {
      pagination: state.pagination,
    },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "QuestionChoices")) return;

    setState({
      ...state,
      loading: true,
    });
    // console.log(_keywords, keywords, _keywords ?? keywords);
    try {
      const res = await choiceClient.getQuestionChoices(
        filterChoiceListId,
        _keywords ?? keywords,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items:
          (res.items as QuestionChoiceDto[]) || ([] as QuestionChoiceDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  async function handleDownloadChoices() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await choiceClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadChoices(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await choiceClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadChoiceContents() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await choiceClient.exportContents();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadChoiceContents(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await choiceClient.uploadContents({ data: f, fileName: f.name });
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  const columns = [
    {
      // title: "ID",
      title: <FormattedMessage id="QuestionChoiceTableHeaderId" />,
      dataIndex: "id",
      key: "id",
      sorter: true,
      defaultSortOrder,
    },
    {
      // title: "Question Choice List",
      title: <FormattedMessage id="QuestionChoiceTableHeaderQuestionChoiceList" />,
      dataIndex: "questionChoiceListName",
      key: "questionChoiceListName",
      sorter: true,
    },
    {
      // title: "Content",
      title: <FormattedMessage id="QuestionChoiceTableHeaderContent" />,
      dataIndex: "content",
      key: "content",
      render: (text, record: QuestionChoiceDto) => (
        <ContentTable content={record.content} />
      ),
      className: "content-table-cell",
    },
    // {
    //   title: "Time added",
    //   dataIndex: "created",
    //   key: "created",
    //   sorter: true,
    //   defaultSortOrder,
    //   render: (d: Date) => <>{d?.toLocaleString()}</>,
    // },
    {
      // title: "Action",
      title: <FormattedMessage id="QuestionhoiceTableHeaderAction" />,
      key: "action",
      render: (text, record: QuestionChoiceDto) =>
        record.id && auth.can(ACTION.Delete, "QuestionChoices") ? (
          <Button
            danger
            ghost
            onClick={() =>
              modal.confirm({
                title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
                okText: <FormattedMessage id="StaticYesLabel" />,
                cancelText: <FormattedMessage id="StaticNoLabel" />,
                onOk() {
                  choiceClient
                    .delete(record.id as number)
                    .then(async () => fetchItems());
                },
              })
            }
          >
            <FormattedMessage id="StaticDeleteButton" />
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, [filterChoiceListId]);

  return (
    <Layout title="QuestionChoiceNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <QuestionMenu />
          <Space justify="space-between">
            <Space justify="flex-start" split={<Divider type="vertical" />}>
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={useIntl().formatMessage({ id: "StaticKeywords" })} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap justify="flex-end">
              {auth.can(ACTION.Download, "QuestionChoices") ? (
                <Button type="primary" onClick={handleDownloadChoices}>
                  <FormattedMessage id="QuestionChoiceContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "QuestionChoices") ? (
                <Upload action={handleUploadChoices}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionChoiceContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Download, "QuestionChoiceContents") ? (
                <Button type="primary" onClick={handleDownloadChoiceContents}>
                  <FormattedMessage id="QuestionChoiceContentDownloadContentButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "QuestionChoiceContents") ? (
                <Upload action={handleUploadChoiceContents}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionChoiceContentUploadContentButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Space justify="flex-start">
            <AsyncSelect
              onChange={setFilterChoiceListId}
              onUpdate={(pageNumber, pageSize) =>
                choiceListClient.getQuestionChoiceLists(
                  null,
                  pageNumber,
                  pageSize,
                  undefined,
                  undefined
                )
              }
              placeholder={
                <FormattedMessage id="QuestionChoiceListStaticSelectLabel" />
              }
            ></AsyncSelect>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
