import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { showError } from "@action";

const Option = Select.Option;

interface IItem {
  value: number | string | undefined;
  label: string | undefined;
}

interface ICustomSelect {
  placeholder: any;
  onChange: any;
  onUpdate: (
    pageNumber: number | undefined,
    pageSize: number | undefined
  ) => Promise<any>;
  valueName: string;
  labelName: string;
  allowClear?: boolean;
}

const defaultPageSize = 10;

const AsyncSelect = (props: ICustomSelect) => {
  const [state, setState] = useState([] as IItem[]);

  const [loading, setLoading] = useState(false);

  const [hasNextPage, setHasNextPage] = useState(true);

  const handleChange = (value: string | number) => {
    props.onChange(value);
  };

  const onScroll = (event: any) => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      hasNextPage
    ) {
      setLoading(true);

      target.scrollTo(0, target.scrollHeight);

      getItems();
    }
  };

  const getItems = async () => {
    const children = [...state];
    const pageNumber = (children.length + defaultPageSize) / defaultPageSize;
    try {
      const data = await props.onUpdate(pageNumber, defaultPageSize);
      setHasNextPage(data.hasNextPage ?? false);

      const items: IItem[] = data.items
        ? data.items.map((value: any) => ({
            value: value[props.valueName],
            label: value[props.labelName],
          }))
        : [];

      items.forEach((value) => {
        children.push(value);
      });

      setLoading(false);
      setState(children);
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <Select
      style={{ width: 200 }}
      allowClear={props.allowClear}
      placeholder={props.placeholder}
      onChange={handleChange}
      onPopupScroll={onScroll}
    >
      {state.map(({ value, label }, index) => (
        <Option key={index} value={value}>
          {label}
        </Option>
      ))}
      {!loading ? null : <Option key="loading">Loading...</Option>}
    </Select>
  );
};

export default AsyncSelect;

AsyncSelect.defaultProps = {
  valueName: "id",
  labelName: "name",
  allowClear: true,
  placeholder: "",
};
