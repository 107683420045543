import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const QuestionMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "QuestionChoiceListNavigationTitle",
        path: "/admin/question/choicelist",
        page: "Questions",
        subpage: "QuestionChoiceLists",
      },
      {
        id: "QuestionChoiceNavigationTitle",
        path: "/admin/question/choice",
        page: "Questions",
        subpage: "QuestionChoices",
      },
      {
        id: "QuestionCategoryNavigationTitle",
        path: "/admin/question/category",
        page: "Questions",
        subpage: "QuestionCategories",
      },
      {
        id: "QuestionFacetDimensionNavigationTitle",
        path: "/admin/question/facetdimension",
        page: "Questions",
        subpage: "FacetDimensions",
      },
      {
        id: "QuestionQuestionNavigationTitle",
        path: "/admin/question/question",
        page: "Questions",
        subpage: "Questions",
      },
    ]}
  />
);

export default QuestionMenu;
