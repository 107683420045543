import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Table, TableProps } from "antd";

interface ICustomTable {
  content?: { [key: string]: string };
}

interface ITable extends TableProps<any>, ICustomTable {}

const ContentTable: FC<ITable> = ({ content: contents, ...rest }) => {
  const columns = [
    {
      dataIndex: "locale",
      key: "locale",
      width: 150,
      ellipsis: true,
      render: (text: string) => <FormattedMessage id={text} />,
    },
    {
      dataIndex: "content",
      key: "content",
      ellipsis: true,
    },
  ];
  const data = Object.keys(contents as object).map((locale) => ({
    locale,
    content: contents?.[locale],
  }));
  return (
    <Table
      columns={columns}
      showHeader={false}
      dataSource={data}
      pagination={false}
    ></Table>
  );
};

export default ContentTable;
